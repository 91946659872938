import { mapGetters } from 'vuex'
import AccountLogin from '@/components/account/AccountLogin.vue'

export default {
  name: 'LoginPage',
  components: { AccountLogin },
  computed: {
    ...mapGetters({
      loggedIn: 'member/loggedIn',
      settings: 'storyblok/settings'
    }),
    hero () {
      return this.settings?.account_login_hero?.[0]
    },
    redirectTo () {
      return this.$route.query.redirect ? decodeURI(this.$route.query.redirect) : 'account'
    }
  },
  watch: {
    loggedIn: {
      handler (val) {
        if (val) {
          this.$router.push(this.$u(''))
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.transparentNavbar = false
    this.$store.dispatch('header/setTransparentNavbar', false)
    this.$store.dispatch('header/setWhiteNavbarText', false)
  },
}
